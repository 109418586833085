import React from 'react';
import './modulesCSS/Projects.css'

function Projects() {
  return (
    <div className="container">
      <div className="section">
        <h1 className="title">Expériences de l’ONG ALPD</h1>
        <p className="text">
        L'ONG ALPD a contribué à quatre typologies de projets. Tout d'abord, elle a réalisé des projets de bout en bout avec des partenaires
         nationaux et internationaux. Ensuite, elle a mené des projets en collaboration avec des acteurs de la société civile sous forme d'enquêtes 
         et de campagnes de sensibilisation. En troisième lieu, elle s'est concentrée sur des projets ciblant les groupes vulnérables. 
         Enfin, elle a mené des activités transverses avec ses partenaires. Dans ce qui suit, nous allons examiner en détail les expériences 
         de l'ONG ALPD dans ces quatre axes illustrés par la suite.
        </p>
      </div>


      <div className="section">
        <h3 className="subtitle"> Axe 1 : Exemples de projets réalisés </h3>
        <ul className="list">
          <li className="listItem">2000 à 2003 : Projet Nutricom </li>
          <li className="listItem">2003 à 2007 : Création de Centres d’Alimentation Communautaire</li>
          <li className="listItem">2005 à 2009 : Distribution de matériel horticole</li>
          <li className="listItem">2007 à 2009 : Projet de rapatriement des réfugiés Mauritaniens</li>
        </ul>
      </div>

      <div className="section">
        <h3 className="subtitle"> Axe 2 : Enquêtes et sensibilisations </h3>
        <ul className="list">
          <li className="listItem">2002 : Participation à l’enquête nutritionnelle</li>
          <li className="listItem">2004 : Campagne de sensibilisation sur les dangers du Sida</li>
          <li className="listItem">2004 à 2005 : Campagne de sensibilisation sur les dangers</li>
          <li className="listItem">2005 : Distribution de préservatifs à Nouakchott</li>
          <li className="listItem">2006 à 2007 : Campagne de sensibilisation sur le processus électoral</li>
        </ul>
      </div>

      <div className="section">
        <h3 className="subtitle"> Axe 3 : Appui aux groupes vulnérables </h3>
        <ul className="list">
          <li className="listItem">2006 : Relance Agricole : distribution de semences</li>
          <li className="listItem">2007 : Assistance d’urgence aux éleveurs et agriculteurs</li>
          <li className="listItem">2005 à 2023 : ALPD est un partenaire opérationnel de l’UNHCR</li>
          <li className="listItem">2021 : Projet d’assistance légale et paralégale des migrants</li>
        </ul>
      </div>

      <div className="section">
        <h3 className="subtitle"> Axe 4 : Activités permanentes avec des partenaires </h3>
        <ul className="list">
          <li className="listItem">Rapatriement des réfugiés Mauritaniens venant du Sénégal.</li>
          <li className="listItem">Assistance aux réfugiés Maliens.</li>
          <li className="listItem">Assistance aux réfugiés urbains à Nouakchott et à Nouadhibou.</li>
        </ul>
      </div>
    <div className="section">
      <p> L'ONG ALPD a également exécuté plusieurs projets en partenariat avec la Banque Mondiale, l’Unicef, le FNUAP, le PNUD, le MSAS, le CSA, la CENI, le PAM, le MIPT, la Communauté DOULOS, l’Ambassade des USA, etc. 
      </p>
      <p> 
      Elle a réalisé plusieurs études dont :
      </p>
      <ul className="list"> 
          <li className="listItem"> Cinq études monographiques dans le cadre du Projet « renforcement des capacités nationales d’asile » 
          en vue d’évaluer les besoins sociaux des migrants, réfugiés et demandeurs d’asile, sur les thèmes : </li>
          <li className="listItem"> Migrants et emploi ;</li>
          <li className="listItem">  Migrants et moyens de communication ;</li>
          <li className="listItem"> Migrants, santé, éducation ;</li>
          <li className="listItem"> Exil, migration, idées de retour au pays d’origine, et Communautés et diaspora étrangère en Mauritanie.</li>    
      </ul>
    </div>  

    <div className="section-specialle">
        <h2 className="subtitle-specialle">Ressources financières</h2>
        <p className="text-specialle">
        Les ressources disponibles de l’ONG émanent de la contribution volontaire de ses membres, 
        des dons et legs de personnes morales ou physiques ou les financements de ses partenaires.
        </p>
      </div>

    </div> 
  );
}

export default Projects;
