// src/components/HomePage.js
import React, { useState } from 'react';
import './modulesCSS/HomePage.css';
import './modulesCSS/PartnersLogos.css';
import './modulesCSS/LogosDropdown.css';

// Liste des logos des partenaires
const logos = [
  { id: 1, src: '/logos/unhcr-logo.png', alt: 'HCR' },
  { id: 2, src: '/logos/FAO-logo.png', alt: 'FAO' },
  { id: 3, src: '/logos/Logo_OIM.png', alt: 'OIM' },
  { id: 4, src: '/logos/UNICEF_Logo.png', alt: 'UNICEF' },
  { id: 5, src: '/logos/UNFPA_logo.svg', alt: 'FUNUAP' },
  { id: 6, src: '/logos/PAM-logo.png', alt: 'PAM-logo' },
  { id: 7, src: '/logos/dos_USA.png', alt: 'DOS USA' },
];

// Composant LogosDropdown défini indépendamment
const LogosDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="logos-dropdown">
      <section>
      <button className="dropdown-button" onClick={toggleDropdown}>
         
        {isOpen ? 'Fermer le menu' : 'Afficher le menu'}
       
      </button>
      </section>
      {isOpen && (
        <div className="partners-logos-container">
          {logos.map((logo) => (
            <img key={logo.id} src={logo.src} alt={logo.alt} className="partner-logo" />
          ))}
        </div>
      )}
    </div>
  );
};

// Composant HomePage
const HomePage = () => {
  return (
    <div>
      <header className='header2'>
        <h1>Association de Lutte contre la Pauvreté pour le Développement</h1>
      </header>
      
      <section className='SectionHomepage'>
        <h2>Notre Cœur de Mission</h2>
        <p>
          Soutenir les franges de la population les plus pauvres, en particulier les femmes, les enfants,
          et les personnes handicapées, à travers des projets de développement durable. Nous visons un
          développement harmonieux et la réduction de la pauvreté.
        </p>
        <p>
          La vocation de l’ONG ALPD est de promouvoir le secours, l’assistance, la solidarité et l’appui
          aux communautés de base démunis et en particulier aux groupes vulnérables marginalisés et/ou
          défavorisés à travers la promotion et la mise en œuvre de projets en leur faveur.
        </p>
      </section>

      <section className='SectionHomepage'>
        <h2>Nos Valeurs</h2>
        <ul>
          <li>Priorité aux personnes vulnérables</li>
          <li>Professionnalisme</li>
          <li>Gestion transparente des ressources</li>
          <li>Partage d’expériences</li>
          <li>Ambition constante</li>
        </ul>
      </section>

      <section className='SectionHomepage'>
        <h2>Zones d'Intervention</h2>
        <p>
          L’ALPD est une ONG à vocation nationale et opère sur tout le territoire mauritanien principalement
          dans les Wilayas du Brakna, le Trarza, le Gorgol, les deux Hodh, l’Assaba, Nouadhibou et Nouakchott.
          Elle dispose d’un siège central à Nouakchott et d’antennes opérationnelles de représentation dans
          les autres Wilayas.
        </p>
      </section>

      <section id='bar' className='SectionHomepage'>
        <h2>Nos partenaires</h2>
        <LogosDropdown /> {/* Utilisation du composant LogosDropdown */}
      </section>

      <section className='SectionHomepage'>
        <h2>Liens utiles</h2>
        <p>
          <a href="https://www.unhcr.org/countries/mauritania" target="_blank" rel="noopener noreferrer">UNHCR</a>
        </p>
        <p>
          <a href="https://www.banquemondiale.org/fr/home" target="_blank" rel="noopener noreferrer">Banque Mondiale</a>
        </p>
        <p>
          <a href="https://www.unicef.fr" target="_blank" rel="noopener noreferrer">UNICEF</a>
        </p>
        <p>
          <a href="https://www.undp.org/fr" target="_blank" rel="noopener noreferrer">PNUD</a>
        </p>
        <p>
          <a href="https://rim-rural.org/2019/01/26/programme-de-renforcement-des-investissements-productifs-et-energetiques-en-mauritanie-pour-le-developpement-durable-des-zones-rurales/" target="_blank" rel="noopener noreferrer">RIMDIR</a>
        </p>
      </section>
    </div>
  );
};

export default HomePage;
