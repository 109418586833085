//le app.js , le vrai pour le routage
import './App.css';

import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Projects from './components/Projects';
import Ressources from './components/Ressources';
import Contact from './components/Contact';
import About from './components/About';


function App() {
  return (
    <Router>
      <Header />
      <main>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/Ressources" element={<Ressources />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        
      </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;

