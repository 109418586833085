import React , { useState } from 'react';
import emailjs from 'emailjs-com';
import './modulesCSS/Contact.css';
import LeafletMap from '../LeafletMap';



      

function Contact() {

  // États pour gérer les champs du formulaire
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Traitement du formulaire (vous pouvez l'envoyer à une API ici)
    console.log('Nom:', name);
    console.log('Email:', email);
    console.log('Message:', message);

    // Réinitialiser le formulaire après la soumission
    setName('');
    setEmail('');
    setMessage('');
    alert('Merci pour votre message ! Nous vous répondrons bientôt.');

     // Envoyer le formulaire rempli à mon adresse mail personnel via la fonction EmailJS

    emailjs.sendForm('service_1kks347', 'template_1m1dh2v', e.target, 'e3PIAFpamZEfcnw5L')
    .then((result) => {
      console.log(result.text);
      alert('Message envoyé avec succès !');
      setName('');
      setEmail('');
      setMessage('');
    }, (error) => {
      console.log(error.text);
      alert('Erreur lors de l\'envoi du message. Veuillez réessayer.');
    });
  

  }
    
   
  return (
  <>
          
            <div className="contact-section"> 
            <h2>Contactez-nous</h2>
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group">
                <label htmlFor="name">Nom:</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows="5"
                  required
                ></textarea>
              </div>
              <button type="submit" className="submit-button">Envoyer</button>
            </form>
          </div>
          <section className='SectionHomepage'>
            <h2>Notre Localisation</h2>
             <LeafletMap />
          </section>
        
        <div className="contact-info">
        <h3> Coordonnées de l’ONG </h3>
          <p><strong>Email:</strong> alpd2001@yahoo.fr / alpdnktt@yahoo.fr</p>
          <p><strong>Phone:</strong> + 222 45 24 45 51 / + 222 46 41 38 11</p>
          <p><strong>Address:</strong> Ilot K n° 80,  Tevragh Zeina </p>
          <p><strong>Boite Postale :</strong>  3867  </p>
       </div>

       </>
      )
  };
      
export default Contact;    