import React from 'react';
import './modulesCSS/Ressources.css'



function Ressources() {
  return (
    <div className="alpd-Ressources">
    <h2>ONG ALPD (Association de Lutte contre la Pauvreté pour le Développement)</h2>
    <p>
      L'ONG ALPD est spécialisée dans divers domaines qui incluent la sensibilisation, le renforcement des capacités, 
      la cohésion sociale, la gestion des conflits, la protection des réfugiés et la protection de l'environnement. 
      Ci-dessous une justification détaillée pour chaque domaine en lien avec les expéricences réalisées.
    </p>
<div className="section"> 
    
      <h2>1. Sensibilisation et Renforcement des Capacités</h2>
      <p>L’ALPD a démontré une expertise notable dans la sensibilisation et le renforcement des capacités à travers divers projets réalisés.</p>
      <ul>
        <li><strong>Sensibilisation à la Nutrition et Santé :</strong> L'ALPD a exécuté le Projet Nutricom à Nouakchott, mettant en place des centres de nutrition communautaire.</li>
        <li><strong>Campagnes de Sensibilisation et Formation :</strong> L’ONG a mené des campagnes de sensibilisation sur le Sida, la malaria, et les dangers des pesticides.</li>
        <li><strong>Distribution de Matériel et Semences :</strong> L’ALPD a fourni du matériel horticole et des semences maraîchères dans plusieurs Wilayas.</li>
      </ul>
          <div className="download-section">
          <p>Pour plus d'informations sur cet axe, vous pouvez télécharger nos attestations de bonne exécution.
          <a href={`${process.env.PUBLIC_URL}/documents/Sensibilisations & renforcement des capacités.pdf`} download="Sensibilisations & renforcement des capacités.pdf" className="download-button">
            Télécharger
          </a>
          </p>
          </div>
    </div>
    <div className="section"> 
    
      <h2>2. Cohésion Sociale et Gestion des Conflits</h2>
      <p>L'ALPD a engagé des activités visant à promouvoir la cohésion sociale et à gérer les conflits.</p>
      <ul>
        <li><strong>Assistance Humanitaire :</strong> La distribution de semences céréalières et d'assistance aux agriculteurs montre leur capacité à atténuer les tensions sociales.</li>
        <li><strong>Protection des Réfugiés :</strong> Le travail de l'ALPD avec le HCR pour le rapatriement des réfugiés mauritaniens illustre son engagement dans la gestion des conflits.</li>
      </ul>
      <div className="download-section">
          <p>Pour plus d'informations sur cet axe, vous pouvez télécharger nos attestations de bonne exécution.
          <a href={`${process.env.PUBLIC_URL}/documents/Cohesion Sociale & Gestion des conflits.pdf`} download="Cohesion Sociale & Gestion des conflits.pdf" className="download-button">
            Télécharger
          </a>
          </p>
          </div>
    
    </div>
    <div className="section"> 
    
      <h2>3. Protection des Réfugiés</h2>
      <p>L'ALPD a accumulé une expérience significative dans la protection des réfugiés à travers plusieurs projets.</p>
      <ul>
        <li><strong>Assistance et Rapatriement :</strong> L'ONG a joué un rôle clé dans le rapatriement des réfugiés mauritaniens du Sénégal.</li>
        <li><strong>Assistance aux Réfugiés Urbains :</strong> Pendant plus de 19 ans, l’ALPD a assuré l’accueil et la protection des réfugiés urbains.</li>
        <li><strong>Assistance Légale :</strong> Le projet d’assistance légale et paralégale des migrants vulnérables montre l’engagement de l’ALPD.</li>
      </ul>
      <div className="download-section">
          <p>Pour plus d'informations sur cet axe, vous pouvez télécharger nos attestations de bonne exécution.
          <a href={`${process.env.PUBLIC_URL}/documents/Assistance & Protection des réfugiés.pdf`} download="Assistance & Protection des réfugiés.pdf" className="download-button">
            Télécharger
          </a>
          </p>
          </div>
    
    </div>

    <div className="section"> 

    
      <h2>4. Protection de l’Environnement</h2>
      <p>L'ALPD a également travaillé dans le domaine de la protection de l'environnement.</p>
      <ul>
        <li><strong>Campagnes de Sensibilisation Environnementale :</strong> La sensibilisation au danger de la réutilisation des emballages vides de pesticides est un exemple de cet engagement.</li>
        <li><strong>Projets Agricoles Durables :</strong> La distribution de matériel horticole et de semences maraîchères témoigne de cet engagement.</li>
      </ul>
      <div className="download-section">
          <p>Pour plus d'informations sur cet axe, vous pouvez télécharger nos attestations de bonne exécution.
          <a href={`${process.env.PUBLIC_URL}/documents/Protection de l'Environnement.pdf`} download="Protection de l'Environnement.pdf" className="download-button">
            Télécharger
          </a>
          </p>
          </div>
    
    </div>
  </div>
  );
}

export default Ressources;

