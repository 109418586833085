// src/components/Header.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './modulesCSS/Header.css';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header>
      <nav className="navbar">
        <div className="navbar-brand" style={{ color: 'white'}}>ONG ALPD</div>
        <button className="navbar-toggler" onClick={toggleMenu} 
         style={{
          backgroundColor: '#none', // Couleur de fond du bouton
          color: '#f8f9fa', // Couleur du texte ou de l'icône
          border: '#f8f9fa', // Optionnel: supprime la bordure du bouton
        }}>
          ☰
        </button>
        <div className={`navbar-menu ${isOpen ? 'is-active' : ''}`}>
          <ul>
            <li>
              <NavLink exact to="/" activeClassName="active" onClick={toggleMenu}>
                Accueil
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" activeClassName="active" onClick={toggleMenu}>
                À propos
              </NavLink>
            </li>
            <li>
              <NavLink to="/Projects" activeClassName="active" onClick={toggleMenu}>
              Projets
              </NavLink>
            </li>
            <li>
              <NavLink to="/Ressources" activeClassName="active" onClick={toggleMenu}>
              Ressources
              </NavLink>      
            </li>
            <li>
              <NavLink to="/contact" activeClassName="active" onClick={toggleMenu}>
                Contacts
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
