// src/components/LeafletMap.jsconst position = [18.090841628578467, -15.986296946536921]; 
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const LeafletMap = () => {
  // Définir la position pour le centre de la carte et le marqueur
  const position = [18.090841628578467, -15.986296946536921]; // Exemple: Paris

  // Fonction pour créer un lien vers Google Maps avec les directions vers la position
  const getDirectionsLink = () => {
    // Coordonnées de départ (peut être modifié selon votre position actuelle ou celle de l'utilisateur)
    const origin = '48.8584,2.2945'; // Exemple: Coordonnées de la Tour Eiffel
    const destination = position.join(','); // La destination est la position spécifiée
    return `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=driving`;
  };

  return (
    <div>
      <MapContainer 
        center={position} 
        zoom={13} 
        style={{ height: '400px', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position}>
          <Popup>
            Association de Lutte contre la Pauvreté pour le Développement
          </Popup>
        </Marker>
      </MapContainer>
      {/* Bouton pour obtenir l'itinéraire vers la position */}
      <a
        href={getDirectionsLink()}
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'inline-block', marginTop: '10px', padding: '10px 20px', backgroundColor: '#4285F4', color: 'white', textDecoration: 'none', borderRadius: '4px' }}
      >
        Obtenir l'itinéraire
      </a>
    </div>
  );
};

export default LeafletMap;

