import React from 'react';
import './modulesCSS/About.css';

function About() {
  return (
    
  <div className="container mt-4">
  <h2>À Propos de Nous</h2>
      
      <section className="section">
        <h2> Qui nous sommes </h2>
        <p>
          L’ALPD est une organisation non gouvernementale, apolitique et à but non lucratif. Elle est leader dans le
          domaine du développement à la base. Elle assure le rôle d’interface entre les groupes bénéficiaires de ses
          programmes et les partenaires au développement.
        </p>
      </section>

      <section className="section">
        <h2>Vision</h2>
        <p>
          La vision de l’ONG ALPD est celle d’un développement juste et équitable qui satisfait les besoins des
          générations actuelles sans compromettre ceux des générations futures.
        </p>
      </section>

      <section className="section">
        <h2>Fondement Juridique</h2>
        <p>
          L’ONG ALPD a été créée le 06 juin 1999 et est reconnue par les pouvoirs publics en Mauritanie à travers le
          récépissé N° 390/MIPT. Elle est régie par la loi 64098 du 09 juin 1964 et ses textes modificatifs de 1973.
          <div className="download-section">
          <p>Pour plus d'informations sur nos projets et réalisations, vous pouvez télécharger notre brochure complète.</p>
          <a href={`${process.env.PUBLIC_URL}/documents/recepALPD.pdf`} download="recepALPD.pdf" className="download-button">
            Télécharger le recepissé de l'ONG
          </a>
          </div>

        </p>
      </section>

      <section className="section">
        <h2>Stratégie et Mission</h2>
        <p>
          La stratégie de l’ALPD s’inspire du Cadre Stratégique de Lutte contre la Pauvreté et de la Stratégie de
          Croissance Accélérée et de Prospérité Partagée (SCAPP 2016-2030). La mission de l’ONG ALPD est de soutenir les
          franges les plus pauvres de la population.
        </p>
      </section>

      <section className="section">
        <h2>Principes et Valeurs</h2>
        <ul>
          <li>La priorité aux personnes vulnérables</li>
          <li>Le professionnalisme</li>
          <li>La gestion saine et transparente des ressources</li>
          <li>Le partage d’expériences</li>
          <li>L’ambition constante</li>
        </ul>
      </section>

      <section className="section">
        <h2>Objectifs</h2>
        <ul>
          <li>Lutte contre la pauvreté et promotion de la cohésion sociale</li>
          <li>Lutte contre la déperdition scolaire, le chômage</li>
          <li>Défense et promotion des droits humains</li>
          <li>Renforcement des capacités</li>
          <li>Protection et assistance des migrants réfugiés</li>
          <li>Développement organisationnel durable</li>
          <li>Assistanat et secours d’urgence aux personnes vulnérables</li>
          <li>Lutte contre MST/VIH/SIDA et autres pandémies</li>
        </ul>
      </section>

      <section className="section">
        <h2>Ressources Humaines</h2>
        <p>
          L’ALPD dispose d’une équipe pluridisciplinaire hautement qualifiée et expérimentée en plus d’un personnel
          d’appui et d’un réseau d’experts pluridisciplinaires.
        </p>
      </section>

    </div>
    
  )
};

export default About;
